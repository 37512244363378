const TERMS = {
  title: 'Terms of Service',
  subtitle: 'These are the terms you agree to when you use SiteBuilder',
  link: '/terms/terms-of-service',
  isInternal: true
}

const PRIVACY_NOTICE = {
  title: 'Privacy Notice',
  subtitle:
    'This Privacy Notice applies to the personal data of users collected or used by Endurance International Group, Inc, doing business as Sitebuilder.',
  link: 'https://newfold.com/privacy-center ',
  isInternal: false
}
const ACCEPTABLE_USE = {
  title: 'Acceptable Use',
  subtitle:
    "This Acceptable Use Policy ('AUP') governs your use of the Services and is incorporated by reference into Sitebuilder's Terms and Conditions.",
  link: '/terms/acceptable-use',
  isInternal: true
}
const TRADEMARK_DISPUTES = {
  title: 'Trademark Disputes',
  subtitle: 'Sitebuilder Trademark Disputes for Domain Names.',
  link: '/terms/trademark-disputes',
  isInternal: true
}
const DOMAIN_REGISTRATION = {
  title: 'Domain Registration',
  subtitle: 'Sitebuilder Domain Registration Agreement.',
  link: '/terms/domain-registration-agreement',
  isInternal: true
}
const COPYRIGHT = {
  title: 'Copyright',
  subtitle: 'Copyright Infringement Policy.',
  link: '/terms/copyright-infringement-policy',
  isInternal: true
}
const DNRN_POLICY = {
  title: 'DNRN Policy',
  subtitle: 'Sitebuilder Domain Name Renewal Notification Policy.',
  link: '/terms/domain-renewal-notification-policy',
  isInternal: true
}
const DATA_REQUEST_POLICY = {
  title: 'Data Request Policy',
  subtitle: 'Sitebuilder Data Request Policy.',
  link: '/terms/data-request-policy',
  isInternal: true
}
const ARBITRATION_AGREEMENT = {
  title: 'Arbitration Agreement',
  subtitle: 'Sitebuilder Arbitration Agreement.',
  link: '/terms/arbitration-agreement',
  isInternal: true
}
const PROFESSIONAL_SERVICES_AGREEMENT = {
  title: 'Professional Services Agreement',
  subtitle: 'Sitebuilder Professional Services Agreement.',
  link: '/terms/professional-services-agreement',
  isInternal: true
}

export const ALL_LINKS = [
  TERMS,
  PRIVACY_NOTICE,
  ACCEPTABLE_USE,
  TRADEMARK_DISPUTES,
  DOMAIN_REGISTRATION,
  COPYRIGHT,
  DNRN_POLICY,
  DATA_REQUEST_POLICY,
  ARBITRATION_AGREEMENT,
  PROFESSIONAL_SERVICES_AGREEMENT
]
