import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const linkStyles = css`
  font-size: 20px !important;
  color: #2196f3 !important;
  text-decoration: none !important;
`

const TermsContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 5%;
`

TermsContainer.section = styled.div`
  margin-bottom: 1%;
`

TermsContainer.link = styled(Link)(linkStyles)

TermsContainer.externalLink = styled.a(linkStyles)

export default TermsContainer
