import React from 'react'
import { ALL_LINKS } from './terms.constants'
import Typography from '@eig-builder/core-ui/Typography'
import TermsContainer from './terms.styles'

const TermsComponent = () => {
  return (
    <TermsContainer>
      {ALL_LINKS.map(({ title, subtitle, link, isInternal }) => (
        <TermsContainer.section key={title}>
          {isInternal ? (
            <TermsContainer.link to={link}>{title}</TermsContainer.link>
          ) : (
            <TermsContainer.externalLink href={link}>{title}</TermsContainer.externalLink>
          )}
          <Typography variant="body1" gutterBottom>
            {subtitle}
          </Typography>
        </TermsContainer.section>
      ))}
    </TermsContainer>
  )
}

export default TermsComponent
